import { type ProductInventoryDataFragment } from '~~/graphql';

type Inventory = ProductInventoryDataFragment['inventory'];

const tKeys = {
    days: { product: 'delivery.days', cart: 'complete.delivery-days', confirmation: 'complete.delivery-days' },
    instant: { product: 'delivery.instant', cart: 'complete.instant', confirmation: 'complete.instant' },
    weeks: { product: 'delivery.weeks', cart: 'complete.delivery-weeks', confirmation: 'complete.delivery-weeks' },
};

export function useDeliveryEstimateText() {
    const { t } = useI18n({
        useScope: 'global',
    });

    return {
        format: (inventory: Inventory, context: 'product' | 'cart' | 'confirmation') => {
            const days = inventory.deliveryEstimateDays ?? undefined;
            const inventoryState = inventory.state ?? undefined;
            const availableQuantity = inventory.availableQuantity ?? undefined;

            // Straight from warehouse -> always fast, estimate days not needed
            if (availableQuantity && availableQuantity > 0) {
                return t(tKeys.days[context], { amount: t('delivery.almost-instant-days') });
            }

            // Straightforward
            if (inventoryState === 'OUT_OF_STOCK') {
                return t('delivery.out-of-stock');
            }

            // Delivery days not defined - instant / fast
            if (days === undefined) {
                return t(tKeys.days[context], { amount: t('delivery.almost-instant-days') });
            }

            if (days <= 1) {
                return t(tKeys.instant[context]);
            }
            if (days === 2) {
                return t(tKeys.days[context], { amount: t('delivery.almost-instant-days') });
            }
            if (days <= 7) {
                return t(tKeys.days[context], { amount: t('delivery.bit-more-days') });
            }
            if (days <= 12) {
                return t(tKeys.weeks[context], { amount: '1-2' });
            }
            if (days <= 19) {
                return t(tKeys.weeks[context], { amount: '2-3' });
            }
            if (days <= 26) {
                return t(tKeys.weeks[context], { amount: '3-4' });
            }
            if (days <= 33) {
                return t(tKeys.weeks[context], { amount: '4-5' });
            }
            if (days <= 40) {
                return t(tKeys.weeks[context], { amount: '5-6' });
            }
            if (days <= 47) {
                return t(tKeys.weeks[context], { amount: '6-7' });
            }
            if (days <= 54) {
                return t(tKeys.weeks[context], { amount: '7-8' });
            }
            if (days <= 61) {
                return t(tKeys.weeks[context], { amount: '8-9' });
            }
            if (days <= 68) {
                return t(tKeys.weeks[context], { amount: '9-10' });
            }
            if (days <= 75) {
                return t(tKeys.weeks[context], { amount: '10-11' });
            }
            if (days <= 82) {
                return t(tKeys.weeks[context], { amount: '11-12' });
            }
            return t('delivery.over-12-weeks');
        },

        // Used in product list / search contexts where full inventory info isn't available
        formatDays: (days: number | undefined) => {
            // Currently we don't have info about stock status in elevate, but
            // only a handful of products that *aren't* out of stock have
            // undefined delivery times, so we're fairly safe displaying a bit
            // pessimistic but not completely out-of-stock text here. Sticking
            // to earlier logic.
            if (days === undefined) {
                return t('delivery.over-4-weeks');
            }

            if (days <= 2) {
                return t('delivery-feed.in-stock');
            }
            if (days <= 7) {
                return t('delivery-feed.fast');
            }
            if (days <= 12) {
                return t('delivery-feed.1-2-weeks');
            }
            if (days <= 19) {
                return t('delivery-feed.2-3-weeks');
            }
            if (days <= 42) {
                return t('delivery-feed.4-6-weeks');
            }
            if (days <= 62) {
                return t('delivery-feed.6-8-weeks');
            }
            return t('delivery-feed.over-10-weeks');
        },
    };
}
