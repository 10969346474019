<template>
    <div v-if="!isAvardaLoaded">
        <v-spinner />

        <p class="pt-sm">{{ t('loading') }}</p>
    </div>

    <div v-else>
        <avarda-payment-widget
            class="relative z-20 overflow-visible"
            :price="price"
            lang="fi"
            account-class="1160">
        </avarda-payment-widget>
    </div>
</template>

<script setup lang="ts">
import { watchDebounced } from '@vueuse/core';

// This renders the official Avarda payment calculator widget, see their official docs:
// https://docs.avarda.com/checkout-3/payment-widget/
// Basically we just perform a request to our backend, initialize the necessary scripts and render the appropriate placeholder component

defineProps<{
    price?: number
}>();

interface WidgetPayload {
    paymentId: string
    widgetJwt: string
    expiredUtc: string
}

interface ResponseDataValue {
    widget?: WidgetPayload[]
}

const widgetData = ref();

const isAvardaLoaded = ref(false);

async function loadWidget() {
    const response: { data: { value: ResponseDataValue } } = await useFetch('/avarda', {
        method: 'GET',
    });

    widgetData.value = response.data.value?.widget || {};
    isAvardaLoaded.value = true;
}

const { t } = useI18n();

const {
    public: {
        avarda: {
            widgetUrl,
        },
    },
} = useRuntimeConfig();

watchDebounced(isAvardaLoaded, async (isLoaded) => {
    if (!isLoaded) {
        return;
    }

    useHead({
        script: computed(() => [{
            src: widgetUrl,
            as: 'script',
            crossorigin: 'anonymous',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'data-payment-id': widgetData.value.paymentId,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'data-widget-jwt': widgetData.value.widgetJwt,
        },
        ]),
    });
}, { debounce: 500 });

onMounted(async () => {
    setTimeout(() => {
        loadWidget();
    }, 500);
});
</script>

<i18n lang="json">
    {
        "et": {
            "loading": "Laadimine..."
        },
        "fi": {
            "loading": "Ladataan laskuria..."
        }
    }
</i18n>
